import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import {query, collection, getFirestore, onSnapshot, connectFirestoreEmulator, where} from "firebase/firestore";
const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];

if (LOCAL_DOMAINS.includes(window.location.hostname))
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");


const firebaseConfig = {
    apiKey: "AIzaSyA5zS4YoFtqzQK2Fj7FZVqEF6YMqJC-FHU",
    authDomain: "infusedaddons-6c472.firebaseapp.com",
    databaseURL: "https://infusedaddons-6c472-default-rtdb.firebaseio.com",
    projectId: "infusedaddons-6c472",
    storageBucket: "infusedaddons-6c472.appspot.com",
    messagingSenderId: "595875757719",
    appId: "1:595875757719:web:6278744217a34600fb33d2",
    measurementId: "G-GZPVPKR1D7"
  };



export default {
    init() {
        const app = initializeApp(firebaseConfig);
        initializeAppCheck(app, {
            provider: new ReCaptchaV3Provider("iverir499r4djeje"),
            isTokenAutoRefreshEnabled: true,
          });
        return app;
    },
    async callFunction(fcn, message = false) {
        const app =  this.init();
        const functions = getFunctions(app);
        if (LOCAL_DOMAINS.includes(window.location.hostname))
            connectFunctionsEmulator(functions, "localhost",5001);
        const fcnCall = httpsCallable(functions, fcn);
        let res;
        if(message) res = await fcnCall(message);
        else res = await fcnCall();

        if(res.data) return res.data;
        else return {};
    },
    onEvent(event, a, b=false) {
        const app = this.init();
        const db = getFirestore(app);
        if (LOCAL_DOMAINS.includes(window.location.hostname))
            connectFirestoreEmulator(db, "localhost",8082);
        let f = () => {};
        let userToken = false;

        if(!b) {
            f = a;
        } else {
            userToken = a;
            f = b;
        }

        let watch = query(collection(db, "gazette"), where("public", "==", true), where("event", "==", event));

        if(userToken) {
            watch = query(collection(db, "gazette"), where("userToken", "==", userToken), where("event", "==", event));
        }


        onSnapshot(watch, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    f(change.doc.data());
                }
            });
        });
    }
};